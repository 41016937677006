import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock, faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import Layout from '../components/layout';
import SEO, { SeoModel } from '../components/seo';
import moment from 'moment';
import { breaklines, linkify } from '../functions/html.functions';

export interface EventNode {
  fields: {
    slug: string
  },
  id: string,
  name: string,
  description: string,
  ticket_uri: string,
  start_time: string,
  end_time: string,
  owner: {
    id: string,
    name: string
  }
  attending_count: number,
  place: {
    name: string,
    location: {
      city: string,
      country: string,
    }
  },
  cover: {
    source: string
  }
  sdayofmonth: string,
  sdate: string,
  syear: string
  smonth: string,
  sday: string,
  edate: string,
}

interface EventPageProps {
  location: Location,
  readonly data: {
    facebookEvents: EventNode
  },
  readonly pageContext: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    previous?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next?: any
  }
}

export const eventTemplateQuery = graphql`
  query EventPageQuery($slug: String!) {
    facebookEvents(fields: { slug: {eq: $slug} }) {
      id
      description
      name
      cover {
        source
      }
      owner {
        id
        name
      }
      place {
        name
        location {
          city
          country
        }
      }
      start_time
      end_time
      sdayofmonth: start_time(formatString: "DD", locale: "nl-NL")
      sday: start_time(formatString: "dddd", locale: "nl-NL")
      smonth: start_time(formatString: "MMM", locale: "nl-NL")
      sdate: start_time(formatString: "DD MMMM YYYY", locale: "nl-NL")
      edate: end_time(formatString: "DD MMMM YYYY", locale: "nl-NL")
    }
  }
`;

const FacebookEventTemplate: React.FC<EventPageProps> = ({
  location,
  data
}) => {
  const event = data.facebookEvents;
      
  const startTime = event.start_time ? moment(event.start_time).format('HH:mm') : '';
  const endTime = event.end_time ? moment(event.end_time).format('HH:mm') : '';

  const seo: SeoModel = {
    title: event.name,
    description: event.description,
    imageUrl: event.cover.source,
    isArticle: true
  };

  return (
    <Layout location={location}>
      <SEO model={seo} />
      <div className="mt-5" />
      <section id="calendar" className={`bg-light text-left px-3 py-5`}>
        <div className="container">
          <p>
            <Link to="/events">Naar lijst van evenementen</Link>
          </p>
          <div className="mt-4 mb-5" key={`event-${event.id}`}>
          <h3 className="text-uppercase"><strong>{event.name}</strong></h3>
            <ul className="list-inline">
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faCalendar} />
                <span> {`${event.sday.substring(0,1).toUpperCase()}${event.sday.substring(1)}`}</span>
                <span> {event.sdate}</span>
              </li>
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faClock} />
                {startTime === '00:00' ? '' : <span> {startTime}</span>}
                {event.edate && event.edate !== event.sdate && endTime !== '00:00' ? <span> tot {event.edate}</span> : ''}
                {!endTime || endTime === '00:00' ? '' : <span> - {endTime}</span>}
              </li>
              <li className="list-item"><FontAwesomeIcon icon={faLocationArrow} /> {event.place && event.place.name}</li>
            </ul>
            <p className="event-content" dangerouslySetInnerHTML={{__html: linkify(breaklines(event.description))}}></p>
            <img className="my-4 shadow mw-100" src={`${event.cover.source}`} />
            {event.ticket_uri?.length > 0 ? <p>
              <a className="text-muted" href={event.ticket_uri} target="_blank">
                Naar ticketinformatie.
              </a>
            </p> : <></> }
            <p>
              <a className="text-muted" href={`https://facebook.com/JubalDordrecht/events`} target="_blank">
                Naar evenementenoverzicht op Facebook.
              </a>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default FacebookEventTemplate;
