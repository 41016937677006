
const urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

export function linkify(text: string): string {
  return text?.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
  }) ?? '';
}

export function breaklines(text: string): string {
  return text?.replace(/\n/g, `<br>`) ?? '';
}